<template>
  <div class="layout">
    <div class="container d-flex flex-column">
      <div
        class="row align-items-center justify-content-center no-gutters min-vh-100"
      >
        <div class="col-12 col-md-5 col-lg-4 py-8 py-md-11">
          <div class="text-center mb-10">
            <img
              :src="logoURL"
              class="mx-auto fill-primary"
              data-inject-svg
              alt
              style="height: 76px;"
            />
          </div>
          <!-- Heading -->
          <h1 class="font-bold text-center">{{ $t('signIn.title') }}</h1>

          <!-- Text -->
          <p class="text-center mb-6">
            {{ $t('signIn.subTitle') }}
          </p>

          <div
            class="alert"
            :class="[`alert-${message.type}`]"
            role="alert"
            v-if="message"
          >
            {{ message.text }}
          </div>

          <!-- Form -->
          <form class="mb-6" @submit="signIn">
            <!-- Email -->
            <div class="form-group">
              <label for="email" class="sr-only">{{
                $t('signIn.email')
              }}</label>
              <input
                type="email"
                class="form-control"
                id="email"
                :placeholder="$t('signIn.placeholders.email')"
                autocomplete="email"
                v-model="credentials.email"
              />
            </div>

            <!-- Password -->
            <div class="form-group">
              <label for="password" class="sr-only">{{
                $t('signIn.password')
              }}</label>
              <input
                type="password"
                class="form-control"
                id="password"
                :placeholder="$t('signIn.placeholders.password')"
                autocomplete="off"
                v-model="credentials.password"
              />
            </div>

            <!-- Submit -->
            <submit-button
              :labels="$t('signIn.submit')"
              :buttonState="buttonState"
              miscButtonClass="btn-block"
            />
          </form>
        </div>
      </div>
      <!-- / .row -->
    </div>
  </div>
  <!-- .layout -->
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'SignIn',
  data() {
    return {
      buttonState: 'default',
      credentials: {
        email: '',
        password: '',
      },
    };
  },
  computed: {
    ...mapState(['message', 'authenticated']),
  },
  methods: {
    ...mapActions(['signInAction']),
    signIn(event) {
      this.buttonState = 'inProgress';
      this.signInAction({
        email: this.credentials.email,
        password: this.credentials.password,
      }).then(() => (this.buttonState = 'default'));
      event.preventDefault();
    },
  },
  watch: {
    authenticated(newValue) {
      if (newValue) {
        if (this.isBlank(this.$route.query?.nextUrl))
          this.$router.push({ name: 'home' });
        else this.$router.replace(this.$route.query.nextUrl);
      }
    },
  },
};
</script>
